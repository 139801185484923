import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {  BehaviorSubject, map, Observable } from 'rxjs';
import { getAuth, signOut, signInWithEmailAndPassword, sendPasswordResetEmail, User, onAuthStateChanged, Auth } from "firebase/auth";
import { ActiveCompanyService } from '../../shared/services/active-company.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomUser } from '../../shared/interfaces/custom-user.interface';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private redirectUrl: string = '';
  user$: CustomUser | null = null;
  private createUserUrl = 'https://createuser-oa66z4esya-uc.a.run.app';
  private setUserRolesUrl = 'https://us-central1-consentimientos-informados.cloudfunctions.net/setUserRoles';
  private deleteUserUrl = 'https://deleteuser-oa66z4esya-uc.a.run.app';
  private deactivateUserUrl = 'https://us-central1-consentimientos-informados.cloudfunctions.net/deactivateUser';
  private toggleUserStatusUrl = 'https://us-central1-consentimientos-informados.cloudfunctions.net/toggleUserStatus';
  private authStatus = new BehaviorSubject<User | null>(null);

  private auth: Auth;

  constructor(private router: Router, private activeCompanyService: ActiveCompanyService, private firestore: AngularFirestore, private http: HttpClient) {
    this.auth = getAuth();
    onAuthStateChanged(this.auth, (user) => {
      this.authStatus.next(user);
    });

  }

    // Método para obtener la instancia de Auth
    getAuth(): Auth {
      return this.auth;
    }

  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }

  getRedirectUrl() {
    return this.redirectUrl;
  }

  async login(email: string, password: string) {
    this.activeCompanyService.loading = true;
    const auth = getAuth();
    
    try {
      // Autenticación de usuario con email y contraseña
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Obtener los roles del usuario desde Firestore
      const userDoc = await this.firestore.collection('companies')
        .doc(this.activeCompanyService.activeCompanyId ?? 'de0bd083-8325-4de0-83b7-772ccd7c354f')
        .collection('users')
        .doc(user.uid)
        .get().toPromise();
  
      let roles: string[] = [];
  
      if (userDoc!.exists) {
        const userData = userDoc!.data();
        if (userData) {
          roles = userData['roles'] ?? [];
          this.user$ = userData as CustomUser;
          this.user$.roles = roles;
        }
      }
  
      // Si no se encuentran roles en Firestore, obtener roles desde los claims del token de ID
      if (roles.length === 0) {
        const idTokenResult = await user.getIdTokenResult();  // Obtener el token de ID y sus claims
        const claims = idTokenResult.claims;
  
        // Verificar si los roles están en los claims
        if (claims['roles']) {
          roles = claims['roles'] as string[];
        }
      }
  
      // Obtener el token de ID para guardarlo en sessionStorage
      const token = await this.getIdToken();
  
      // Guardar solo el email, roles y token en SessionStorage
      const userSessionData = {
        email: user.email,
        roles: roles,
        token: token
      };
      
      sessionStorage.setItem('currentUser', JSON.stringify(userSessionData));
  
      
      this.activeCompanyService.loading = false;
      // const redirectUrl = this.getRedirectUrl() || '/dashboard';
      // this.router.navigate([redirectUrl]);
      // this.router.navigate(['admin-console']);
  
    } catch (error) {
      console.error('Login error:', error);
      this.activeCompanyService.loading = false;
    }
  }
  

  async logout() {
    this.activeCompanyService.loading = true;
    const auth = getAuth();
    try {
      await signOut(auth);
      this.activeCompanyService.loading = false;
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('active_patient');
      this.router.navigateByUrl('/login');
    } catch (error) {
      console.error('Logout error:', error);
      this.activeCompanyService.loading = false;
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('active_patient');
      this.router.navigateByUrl('/login');
    }
  }

  isLoggedIn(): boolean{
    return this.authStatus.value !== null;
    // const auth = getAuth();
    // return auth.currentUser !== null; 
  }

  getAuthStatus(): Observable<User | null> {
    return this.authStatus.asObservable();
  }

  getUserInfo(){
      const auth = getAuth();
      this.user$ = auth.currentUser;
      return this.user$; 
  }

  // Método de registro de usuarios usando cloudFunctions
  async register(email: string, password: string, roles: string[], companyId: string): Promise<{ success: boolean; message: string }> {
    try {
      // Obtén el token JWT del usuario actual
      const token = await this.getIdToken();
      
      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`
      });
  
      const result = await this.http.post<{ success: boolean; message: string }>(this.createUserUrl, {
        email,
        password,
        roles,
        companyId
      }, { headers }).toPromise();
  
      return { success: result!.success, message: result!.message };
    } catch (error) {
      console.error('Error al registrar usuario:', error);
      return { success: false, message: 'Error al crear el usuario.' };
    }
  }

 
async getIdToken(): Promise<string> {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    return await user.getIdToken(/* forceRefresh */ true); // Esto asegura que el token sea válido
  }
  throw new Error('Usuario no autenticado');
}

// Función para obtener todos los usuarios de una compañía dada por su companyId
getUsersByCompanyId(companyId: string): Observable<any[]> {
  return this.firestore
    .collection(`companies/${companyId}/users`)
    .snapshotChanges()
    .pipe(
      map(actions => 
        actions.map(a => {
          const data = a.payload.doc.data() as CustomUser || {};
          const id = a.payload.doc.id;
          const roles = data.roles || []; // Asegurarnos de que roles sea un array

          // Crear un objeto que contiene las propiedades para cada rol
          const roleFlags = {
            company_admin: roles.includes('company_admin') || false,
            company_user: roles.includes('company_user') || false,
            company_medical_staff: roles.includes('company_medical_staff') || false,
            company_administrative_staff: roles.includes('company_administrative_staff') || false,
          };

          return { 
            id, 
            email: data.email,
            roles: roles, // Incluir el array de roles original
            isActive: data.isActive ?? true,
            ...roleFlags 
          };
        })
      )
    );
}
  
 // Método para asignar roles a un usuario usando la API REST
 async assignRolesToUser(uid: string, roles: string[], companyId: string): Promise<Observable<any>> {
  const token = await this.getIdToken(); // Debes obtener el token del usuario autenticado
  const headers = new HttpHeaders({
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  });

  const body = { uid, roles };

      await this.firestore.collection('companies').doc(companyId).collection('users').doc(uid).update({
        roles: roles, // Almacena los roles en Firestore
      });

  return this.http.post(this.setUserRolesUrl, body, { headers });
}

// Método para borrar un usuario de firebase authentication
async deleteUser(uid: string, companyId: string) {
  const token = await this.getIdToken();
  const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  return this.http.delete(this.deleteUserUrl, {
    headers,
    body: { uid, companyId }
  }).toPromise();
}

// metodo para solicitar el correo de restablecer contraseña
async getPasswordResetEmail(email: string): Promise<void> {
  const auth = getAuth();
  try {
    await sendPasswordResetEmail(auth, email);
    console.log('Password reset email sent successfully');
  } catch (error) {
    console.error('Error sending password reset email:', error);
  }
}

// Método para desactivar un usuario
async deactivateUser(uid: string, companyId: string) {
  const token = await this.getIdToken();
  const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  return this.http.post(this.deactivateUserUrl, 
    { uid, companyId },
    { headers }
  ).toPromise();
}

// Método para activar o desactivar un usuario
async toggleUserStatus(uid: string, companyId: string, isActive: boolean) {
  const token = await this.getIdToken();
  const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  return this.http.post(this.toggleUserStatusUrl, { uid, companyId, isActive }, { headers }).toPromise();
}



}